:root {
    --yellow: #ffee3b;
  }
  
.container {
    position:fixed;
    width:100vw;
    height:100vh;
    background:var(--yellow);
    z-index:9999;
    top:62px;
    left:100vw
}