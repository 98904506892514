@font-face {
  font-family: 'Aloja';
  src:  url('../assets/font/Aloja-Light.woff2') format('woff2'),
        url('../assets/font/Aloja-Light.woff') format('woff');
}
@font-face {
  font-family: 'Bodoni';
  src:  url('../assets/font/bodoni-seventytwo-itc-book.woff2') format('woff2'),
        url('../assets/font/bodoni-seventytwo-itc-book.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src:  url('../assets/font/Roboto-Light.woff2') format('woff2'),
        url('../assets/font/Roboto-Light.woff') format('woff');
}



a, a:visited{
  text-decoration:none;
  color:black;
  cursor:pointer
}

:root {
  --yellow: #ffee3b;
}

.App {
  text-align: center;
  margin:0 auto;
  /* max-width: 900px; */
}

.logo{
  padding:10px 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes arrowAnim {
  0%{
    margin-top:-0px
  }
  50%{
    margin-top:-30px
  }
  100%{
    margin-top:-0px
  }
}


